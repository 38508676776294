<template>
	<div>
		<a-modal id='homehand' width='648px' v-model='visible' on-ok='handleok' :dialog-style='{top:"25vh"}'
			@cancel='handCancel' :footer='null' :keyboard='false' :maskClosable='false'>
			<div class='mark-file'></div>
			<div solt='title'></div>
			<div class='first_div'>
				<div class='firts_gre' style='width: 600px;'>
					<div style='background-color: #fff;text-align: center;padding: 20px 0 0;'>
						<!-- <img :src='require("@/image/login.png")' width='80px' /> -->
						<div style='margin: 20px auto 35px'>
							<div style='font-size: 20px;font-weight: 600;margin-bottom: 18px;'>立即询价</div>
							<div class='divmax'>欢迎电邮 info@qmisit.com 或致电查询:0411-84507399咨询报价。</div>
						</div>
						<div class='pas_btn' @click='handCancel()'>完 成</div>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: [],
		data() {
			return {
				visible: true,
			}
		},
		methods: {
			handCancel() {
				this.$emit('child', 0)
			}
		}
	}
</script>

<style lang='less'>
	#homehand {
		.ant-modal-close-x {
			display: none;
		}

		.divmax {
			font-size: 18px;
		}

		.firts_gre {
			width: 600px;
			border-radius: 4px;
			overflow: hidden;
			position: relative;
			/* margin-top: -22vh; */
		}

		.mark-file {
			position: absolute;
			top: 0;
			left: 0;
		}

		.pas_btn {
			background-color: #1697A7;
			color: #fff;
			width: 110px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 40px auto 20px;
			font-size: 18px;
			cursor: pointer;
		}
	}
</style>
