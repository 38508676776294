<template>
	<div id="sreachpage">
		<div class='heard'>
			<div id='wrap_hand' class='wrap_hand'>
				<div class='heard_left'><img src="@/image/onhand/logo.png" alt="" @click='$router.push("/")'></div>
				<div class='heard_right'><img src="@/image/onhand/icon.png" alt=""></div>
			</div>
			<div class='isbody'>
				<a-row style='padding: 20px 0;border-bottom: 1px solid #d8d8d8;'>
					<a-col :span='20'><input type="text" v-model='params.dominname' class='warp_input' placeholder=' '
							@keydown='handenter'></a-col>
					<a-col :span='4'>
						<div style="text-align: right;"><button class='warp_btn1' @click='getdata'>搜索</button>
						</div>
					</a-col>
				</a-row>
				<div v-for='(item,ind) in formtable' class='mytable'>
					<div class='myname'>{{item.dominname}}
						<div class='yzhuci' v-if='item.flag==1'>已注册</div>
						<div class='wzhuce' v-else>未注册</div>
					</div>
					<div class='xunjia' @click='island = true'>立即询价</div>
				</div>
				<div class="paginationView">
					<div>
						<!-- 第{{firstPage}}到{{secPage}}條，共{{params.total}}條記錄 -->
					</div>
					<div style="margin-left:auto;">
						<a-pagination v-model="params.pageNum" @change="pageChange" :total="params.total"
							:pageSize="params.perPage" />
					</div>
					<!-- <div class="pageTzView">
						<input type="text" v-model="tzPageNum" @onkeyup="valueFn" @onafterpaste="valueFn">
						<span @click="tzEvent">跳轉</span>
					</div> -->
				</div>
			</div>

			<div style="background-color: #fff;">
				<div style="width: 90%;margin: 5px auto;display: flex;align-items: center;justify-content: space-between;
    padding: 10px 0;">
					<div style="display: flex;align-items: center;">
						<img :src="item" alt="" v-for='(item,a) in listdata.lastlog' :key='a+692'>
					</div>
					<div style="float: right;font-size: .8rem;text-align: right;color: #000000;">
						© Copyright 大连铧亚软件科技开发有限公司. All Rights Reserved
						<!-- <br>
						辽ICP备2021010794号-1 -->
					</div>
				</div>
			</div>
		</div>
		<a-back-top id='my-back-top-demo-custom'>
			<div class="ant-back-top-inner">
				<img :src="require('@/image/onhand/up.png')" alt="">
			</div>
		</a-back-top>
		<handitem v-if='island' @child='handback'></handitem>
	</div>
</template>

<script>
	import handitem from './handitem.vue'

	export default {
		components: {
			handitem
		},
		data() {
			return {
				loading: true,
				tzPageNum: '',
				formtable: [],
				params: { // 任務數
					pageNum: 1, //當前頁數
					total: 1,
					perPage: 10, //每頁條目數
					dominname: '',
					pages: ''
				},
				island: false,
				listdata: {
					lastlog: [require('@/image/onhand/paypal.png'), require('@/image/onhand/visa.png'), require(
						'@/image/onhand/alipay.png'), require('@/image/onhand/wechat.png')],
					a2: '专注于用户体验 专注于解决方案',
				},
			}
		},
		computed: {
			firstPage: function() {
				let _this = this;
				let a = (_this.params.pageNum - 1) * 10;
				let b = 1;
				return _this.params.total > 0 ? Number(a) + Number(b) : 0
			},
			secPage: function() {
				let _this = this;
				let a = _this.params.pageNum * 10;
				let b = a > _this.params.total ? _this.params.total : a;
				return b
			},
		},
		mounted() {
			this.params.dominname = this.$route.query.userId
			this.getdata()
		},
		methods: {
			handenter(e) {
				var evt = window.event || e;
				if (evt.keyCode == 13) {
					this.getdata()
				}
			},
			tzEvent() {
				let _this = this;
				let maxPageNum = Math.ceil(_this.params.total / _this.params.perPage);
				if (_this.tzPageNum >= maxPageNum) {
					_this.params.pageNum = Number(_this.tzPageNum);
					_this.messLoad();
				} else {
					App.oTool.warningT('請輸入有效的頁碼');
				}
			},
			pageChange(page, pageSize) {
				this.params.pageNum = page;
				this.getdata();
			},
			valueFn() {
				this.tzPageNum = this.tzPageNum.replace(/\D/g, '')
			},
			handback() {
				this.island = false
			},
			getdata() {
				if (this.loading&&this.params.dominname) {
					this.loading = false
					let str = JSON.parse(JSON.stringify(this.params.dominname))
					this.$ajax({
						method: 'post',
						url: this.$api.checkDomain,
						data: this.params
					}).then(res => {
						if (res.data.result) {
							this.formtable = res.data.result
							this.params = res.data
							this.params.dominname = str
						}else{
							this.$message.warning(res.data.qmismsg)
						}
						this.loading = true
					}).catch(err=>{
						this.$message.warning(err.data.qmismsg)
						this.loading = true
					})
				}
			}
		}
	}
</script>
<style lang='less'>
	#sreachpage {

		.mytable {
			border-bottom: 1px solid #d8d8d8;
			display: flex;
			justify-content: space-between;
			padding: 18px 0;
			align-items: center;
		}

		.yzhuci {
			background-color: #e5e5e5;
			color: #999999;
			margin-left: 16px;
			padding: 0 3px;
		}

		.wzhuce {
			background-color: #dcfafa;
			color: #40bfbf;
			margin-left: 16px;
			padding: 0 3px;
		}

		.myname {
			display: flex;
			color: #000;
		}

		.xunjia {
			border: 1px solid #40bfbf;
			color: #40bfbf;
			padding: 2px 5px;
			cursor: pointer;
		}

		.isbody {
			padding: 0 20%;
			border-top: 1px solid #f5f5f5;
			background-color: #fff;
		}

		.warp_input {
			width: 100%;
			height: 2.5rem;
			background-color: #fff;
			border: 1px solid #40bfbf;
			/* font-size: 1rem; */
			/* appearance: auto; */
			padding: 0 10px;
			color: #000;
		}

		.warp_input::-webkit-input-placeholder {
			/* WebKit browsers */
			/* color: #bababa;  */
			font-size: .9rem;
		}

		.warp_btn1 {
			cursor: pointer;
			outline: none;
			width: 8rem;
			height: 2.5rem;
			background-color: #40bfbf;
			border: 0;
			font-size: 1rem;
			color: #fff;
			/* margin-top: 2.6rem; */
		}

		#my-back-top-demo-custom {
			box-sizing: border-box;
			margin: 0;
			padding: 0;
			color: rgba(0, 0, 0, 0.65);
			font-size: 16px;
			font-variant: tabular-nums;
			line-height: 1.5;
			list-style: none;
			font-feature-settings: 'tnum';
			position: fixed;
			right: 50px;
			bottom: 105px;
			text-align: center;
			border-radius: 4px;
			z-index: 10;
			cursor: pointer;
		}

		/* .heard {
			background-color: #f5f5f5;
		} */

		.homepage {
			width: 100%;
			overflow: hidden;
		}

		.heard_left {
			float: left;
			padding: 10px;
			cursor: pointer;
		}

		.heard_right {
			float: right;
			padding-top: 1.3rem;
		}

		.wrap_hand {
			overflow: hidden;
			/* position: absolute; */
			width: 100%;
			padding: 2px 4.5%;
			z-index: 100;
			background: rgba(255, 255, 255, 0.7);
		}

		.paginationView {
			padding: 1rem 0;
			display: flex;
			align-items: center;
			width: 100%;
		 color: #666666;

			/* height:calc(100vh - 142px); */
			i {
				margin: 0;
			}
		}
	}
</style>
